<template>
  <div class="login_container" style="height: 100%;">
    <div class="main">
      <div class="slogan">
        <div class="logo"><img src="@assets/v3-logo.png" /></div>
        <h1>私域宝<span style="margin:0 2px;">•</span>聚合聊天系统</h1>
      </div>

      <div class="form_c">
        <div class="form_item">
          <el-input
            v-model="formObj.tel"
            class="input_item"
            placeholder="请输入登录账号"
          />
        </div>
        <div class="form_item">
          <el-input
            v-model="formObj.pwd"
            class="input_item"
            type="password"
            placeholder="请输入登录密码"
            show-password
          />
        </div>
      </div>

      <h3 style="display: flex;align-items: center;margin-bottom: 5px">记住密码：<el-checkbox v-model="remamberPws"  size="large" /></h3>
      <div class="btns">
        <el-button
          type="success"
          style="width: 100%; height: 50px"
          @click="handleSubmit"
          :disabled="!relogInAble && (!websocketStatus || loadingUserInfo)"
        >
          登录</el-button
        >
      </div>

      <div style="height: 35px">
        <div
          style="
            text-align: center;
            margin-top: 20px;
            color: #20232a;
            opacity: 0.5;
            font-size: 12px;
          "
          v-if="!websocketStatus"
        >
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          服务器连接中...
        </div>
      </div>
      <div
        style="text-align: center; color: #20232a; opacity: 0.5"
        v-if="websocketStatus && loadingUserInfo"
      >
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        登录用户信息加载中...
      </div>
    </div>
  </div>
</template>
<script>
import {
  reactive,
  inject,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  toRefs,
  computed,
  ref,
} from "vue";
import { useStore } from "vuex";
import { login, initUserSystemData } from "@/api/userapi";
import { ElNotification, ElMessage } from "element-plus";
import { useRouter } from "vue-router";
export default {
  components: {},

  setup() {

    //测试electron
    const handleReadLile = async()=>{
      console.log('---------------------------')
      let data = await window.myApi?.startTime()
      console.log(data)
    }

    // const v3layer = inject("v3layer");
    //const utils = inject("utils");
    const router = useRouter();
    const wsCmd = inject("wsCmd");
    const eventbusCms = inject("eventbusCms");
    const formObj = reactive({});
    let store = useStore();
    const _this = getCurrentInstance().appContext.config.globalProperties; //vue3获取当前this

    const bus = inject("$eventBus");

    const remamberPws = ref(true)

    // 登录表单--------------------------------------
    const loginFormRef = ref(undefined);

    const websocketStatus = computed(() => {
      return store.state.websocket.socket.isConnected;
    });

    const data = reactive({
      //是否可以重新登录
      relogInAble: false,
      loadingUserInfo: false,
    });

    function loginResProcess(loginResp) {
      if (!loginResp.code && loginResp.code !== 0) {
        return;
      }

      if (loginResp.code === 0) {
        ElMessage.error(loginResp.msg || "登录失败");
        //VTips(loginResp.msg || '登录失败');
        return;
      }

      //如果是记住密码将账号密码保存
      if(remamberPws.value){
        store.dispatch('user/rememberPassword',formObj)
      }else{
        store.dispatch('user/unrememberPassword')
      }

      //清理登录响应信息
      // _this.$store.commit("wsData/SET_LOGIN_RES_NULL");

      let userInfo = loginResp.data;

      // console.log('登录成功')
      // console.log(loginResp)
      _this.$store.commit("user/SET_TOKEN", userInfo.token);
      _this.$store.commit("user/SET_USER", userInfo);
      _this.$store.commit("wsData/SET_CHATING_USERID", userInfo.uid);
      //初始化会话消息
      _this.$store.commit("wsData/INIT_CHAT_DATA");

      //初始化系统配置信息
      initUserSystemData().then(() => {
        //提示用户登录成功，并加载用户配置信息
        data.loadingUserInfo = true;
        setTimeout(() => {
          data.loadingUserInfo = false;
          data.relogInAble = true;
          //ElMessage.error('获取系统配置信息失败')
        }, 5000);
      });
    }

    const syscfgInfo = computed(() => {
      return store.state.wsData.sysCfgInfo;
    });

    //登录配置信息加载完成
    function loginSystemInitSuccess() {
      
      ElNotification({
        title: "提示信息",
        message: "恭喜，登录成功啦！",
        type: "success",
        duration: 1000,
        onClose: () => {
          router.push("/chat/group-chat");
        },
      });
    }

    const handleSubmit = () => {
      if (!formObj.tel) {
        ElMessage.error("账号不能为空！");
        //VTips("手机号不能为空！");
        // } else if (!utils.checkTel(formObj.tel)) {
        //   VTips("手机号格式不正确！");
        // }
      } else if (!formObj.pwd) {
        ElMessage.error("密码不能为空！");
        //VTips("密码不能为空！");
      } else {
        //let data = {t: 1, d: }
        data.relogInAble = false;
        data.loadingUserInfo = false;
        login({ username: formObj.tel, password: formObj.pwd }).then((res) => {
          console.log("res", res);
        });
      }
    };

    onMounted(() => {
      data.loadingUserInfo = false;

      //是否有记住密码
      let remembermeInfo = store.state.user.syb_webchat_me
      if(remembermeInfo?.tel){
        Object.assign(formObj,remembermeInfo)
      }

    
      bus.on(`${wsCmd.cmd_1_resp}`, loginResProcess); // listen
      bus.on(eventbusCms.sysCfgInitSuccess, loginSystemInitSuccess); // listen
    });

    onUnmounted(() => {
      bus.off(`${wsCmd.cmd_1_resp}`);
      bus.off(eventbusCms.sysCfgInitSuccess); // listen
      document.onkeyup = null;
    });

    onMounted(() => {
      handleReadLile()
      document.onkeyup = (e) => {
        if (e.keyCode == 13) {
          handleSubmit();
        }
      };
  });

 
    return {
      loginFormRef,
      formObj,
      ...toRefs(data),
      websocketStatus,
      syscfgInfo,
      remamberPws,
      handleSubmit,
      loginResProcess,
      loginSystemInitSuccess,
    };
  },
};
</script>



<style lang="scss" scoped>
 
.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
 
  .main {
    width: 664px;
    height: 400px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 50px 21px rgba(0,0,0,0.1);
    .slogan {
      display: flex;
      justify-content: center;
      align-items: center;
      .logo img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
      .s {
        display: inline-block;
        height: 5px;
        width: 5px;
        background-color: black;
        border-radius: 100%;
        position: relative;
        top: 2px;
        margin: 0 2px;
      }
    }

    .form_c {

      .form_item {
        height: 50px;
        .input_item {
          height: 100%;
        }
        margin-bottom: 20px;
      }
    }
  }
}

.lds-ring {
  /* change color here */
  color: #1c4c5b;
  padding: 5px;
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>